@import '/src/colors.css';
@import '/src/fonts/fonts.css';

.keyfiguresbackgroundwrapper{
    background: var(--mintblue);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.keyfigureswrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 32px;
}

.keyfiguresheading{
    color: white;
    width: 100%;
    box-sizing: border-box;
}

.keyfigure-text{
    color: var(--purple);
    text-align: center;
}

.keyfigureswrapper .keyfigure-counter{
    color: var(--purple);
    text-align: center;
}

.keyfiguresflexwrapper{
    display: flex;
    width: 100%;
    gap: 24px;
}

.keyfigureseperator{
    width: 100%;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.keyfigure-counter.show{
    opacity: 1;
}

.keyfigure-counter{
    overflow: visible;
    opacity: 0;
    transition: all 0.9s ease;
}

@media only screen and (max-width: 560px){
    .keyfigureswrapper{
        padding: 56px 24px 56px 24px;
    }

    .keyfiguresheading{
        text-align: center;
    }

    .keyfiguresflexwrapper{
        flex-direction: column;
    }
}

@media only screen and (min-width: 560px){
    .keyfigureswrapper{
        padding: 104px 24px 104px 24px;
    }

    .keyfiguresheading{
        text-align: center;
    }

    .keyfiguresflexwrapper{
        flex-direction: column;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 1100px){
    .keyfigureswrapper{
        padding: 104px 220px 104px 220px;
    }

    .keyfigure-text{
        white-space: nowrap;
        overflow: visible;
    }
}

@media only screen and (min-width: 1358px){
    .keyfiguresflexwrapper{
        flex-direction: row;
        max-width: 1000px;
    }

    #middleseperator{
        width: 330px;
    }

    .outerseperator{
        max-width: 270px;
    }

    .keyfiguresheading{
        text-align: left;
        max-width: 1000px;
        padding: 0 104px 0 104px;
    }
}