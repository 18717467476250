@import '../../../colors.css';
@import '../../../fonts/fonts.css';

.newsletter{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.email-field{
    margin-right: 24px;
    height: 40px;
    width: 100%;
    border: none;
    border-radius: 16px;
    background: white;
    padding:0 0 0 16px;
}

.email-field:focus{
    outline: none;
}

.email-field::placeholder{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    font-family: Karla;
}

.datenschutz{
    height: 24px;
    display: flex;
}

.datenschutz-text{
    margin: 0;
    display: inline-block;
    color: var(--purple);
}

.socials .icon-service_insta,.socials .icon-service_mail{
    font-size: 60px;
    height: 60px;
    color: var(--purple);
    display: inline-block;
    transition: all 0.3s ease;
}

.footer-wrapper{
    height: fit-content;
    background: var(--purple);
    width: 100%;
}

a{
    text-decoration: none;
}

@media only screen and (max-width: 560px){
    .newsletter-wrapper{
        height: fit-content;
        background: var(--mintblue);
        padding: 32px 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .socials{
        margin-top: 16px;
        margin-left: 24px;
        min-width: 109px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .insta-link{
        width: fit-content;
        height: fit-content;
        margin-right: 16px;
    }
    
    .mail-link{
        width: fit-content;
        height: fit-content;
    }

    .otherlinks-wrapper{
        display: flex;
        flex-direction: row;
        gap: 16px;
        width: 100%;
    }

    .flex-wrapper{
        padding: 24px 16px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        gap: 16px;
    }

    .otherlinks{
        width: 100%;
    }

    .footer-wrapper .footnote{
        color: white;
        display: inline-block;
        margin: 0;
        width: 100%;
        text-align: center;
    }
}

@media only screen and (min-width: 560px){

    .placeholdertext{
        width: 100%;
    }

    .socials{
        min-width: 42.5px;
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .insta-link{
        width: fit-content;
        height: fit-content;
    }
    
    .mail-link{
        width: fit-content;
        height: fit-content;
    }

    .insta-link:hover .icon-service_insta{
        color: var(--pink);
    }
    
    .mail-link:hover .icon-service_mail{
        color: var(--pink);
    }

    .newsletter-wrapper{
        height: fit-content;
        background: var(--mintblue);
        padding: 32px 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .otherlinks-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 250px;
    }

    .flex-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 64px;
        padding-right: 24px;
        padding-left: 24px;
    }

    .footnote{
        color: white;
        display: inline-block;
        margin: 0;
        width: fit-content;
    }
}

@media only screen and (min-width: 1100px){
    .flex-wrapper, .newsletter-wrapper{
        padding-right: 120px;
        padding-left: 120px;
    }
    
    .flex-wrapper{
        height: 80px
    }

    .placeholdertext{
        max-width: 690px;
        width: 100%;
    }

    .socials{
        margin-left: 24px;
        min-width: 109px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .insta-link{
        width: fit-content;
        height: fit-content;
        margin-right: 24px;
    }
    
    .mail-link{
        width: fit-content;
        height: fit-content;
    }

}