@import '/src/colors.css';
@import '/src/fonts/fonts.css';
@import '/src/button.css';

.introwrapper{
    background-color: var(--purple);
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 560px){
    .introwrapper{
        padding: 80px 16px 80px 16px;
    }

    .introtext{
        max-width: 820px;
    }
}

@media only screen and (min-width: 560px){
    .introwrapper{
        padding: 144px 24px 144px 24px;
        justify-content: center;
    }

    .introtext{
        padding-right: 124px;
    }
}

@media only screen and (min-width: 1100px){

    .introwrapper{
        padding: 144px 324px 144px 324px;
        justify-content: center;
    }

    .introtext{
        max-width: 1000px;
        padding-right: 180px;
    }
  }