@import '/src/colors.css';
@import '/src/fonts/fonts.css';
@import '/src/button.css';

.anfunkenwrapper{
    background-color: var(--purple);
    display: flex;
    justify-content: center;
}

.anfunkencontentwrapper{
    max-width: 1000px;
}

.anfunkentextwrapper{
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: white;
    max-width: 1000px;
}

.anfunkenkategoriewrapper{
    display: flex;
}

.anfunkenkategorie{
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    padding: 40px;
    background-color: var(--blue);
    color: white;
    gap: 24px;
    transition: all 0.8s ease;
    max-height: 214px;
    box-sizing: border-box;
}

.anfunkenkategoriebutton{
    margin: 0;
    visibility: hidden;
    transition: all 0.4s ease;
    opacity: 0;
}

.anfunkenkategorieheading{
    text-align: center;
}

.anfunkenkategorietext{
    visibility: hidden;
    transition: all 0.4s ease;
    opacity: 0;
    max-width: 500px;
}

.anfunkenkategoriewrapper:nth-child(odd){
    justify-content: flex-end;
}

.anfunkenkategorie:hover .anfunkenkategoriebutton, .anfunkenkategorie:hover .anfunkenkategorietext, .anfunkenkategorie:active .anfunkenkategoriebutton, .anfunkenkategorie:active .anfunkenkategorietext{
    visibility: visible;
    transition: all 0.1s ease;
    opacity: 1;
}

.anfunkenkategorie:hover, .anfunkenkategorie:active{
    max-height: 600px;
    background: none;
    border: 4px solid #FFFFFF;
}


@media only screen and (max-width: 560px){
    .anfunkencontentwrapper{
        display: flex;
        flex-direction: column;
        padding: 128px 16px 16px 16px;
        gap: 16px;
    }

    .anfunkenkategorie{
        width: 100%;
        max-height: 176px;
    }

    .anfunkentextwrapper{
        margin-bottom: 16px;
    }

    .anfunkenkategorietext{
        width: calc(100vw - 120px);
    }
}

@media only screen and (min-width: 560px){
    .anfunkencontentwrapper{
        display: flex;
        flex-direction: column;
        margin: 162px 24px 16px 24px;
        gap: 56px;
    } 

    .anfunkenkategorie{
        width: 472px;
        max-height: 196px;
    }

    .anfunkenkategorietext{
        max-width: 500px;
    }
}

@media only screen and (min-width: 1100px){
    .anfunkencontentwrapper{
        margin: 182px 222px 144px 222px;
    }

    .anfunkenkategorie{
        width: 588px;
        max-height: 220px;
    }

    .anfunkentext{
        margin: 0 102px;
    }

}