@import '/src/colors.css';
@import '/src/fonts/fonts.css';
@import '/src/button.css';

.nexteventwrapper{
    background: var(--mintblue);
    width: 100%;
    height: fit-content;
    padding: 64px 0 64px 0;
}

.datetext{
    color: var(--purple);
    text-align: center;
}
  
.datesection{
    white-space: nowrap;
}
  
.date{
    font-size: 252px;
    line-height: 252px;
    color: var(--purple);
    display: inline-block;
}

.mainheading{
    color: white;
    height: fit-content;
    display: block;
    overflow: visible;
    text-align: center;
    margin-bottom: 32px;
}

.nexteventlinks{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 700px){
    .dateflex{
      display: flex;
      flex-direction: column;
    }
  
    #period{
      display: none;
    }
  
    .date{
      line-height: 84%;
      text-align: center;
      width: 100%;
      font-size: 170px;
    }

    .nexteventflexcontainer{
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
}

@media only screen and (min-width: 700px){
    .date{
      font-size: 180px;
    }

    .dateflex{
      display: flex;
      flex-direction: row;
    } 
  
    .nexteventflexcontainer{
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .datetext{
      max-width: 480px;
    }
}

@media only screen and (min-width: 1100px){
    .date{
      font-size: 240px;
    }

    .nexteventflexcontainer{
      padding-left: 220px;
      padding-right: 220px;
      gap: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .datetext{
      max-width: 480px;
    }
  }