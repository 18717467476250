@import '/src/colors.css';
@import '/src/fonts/fonts.css';

.sttbutton{
    width: 56px;
    height: 56px;
    box-sizing: border-box;
    border: 4px solid var(--pink);
    background-color: transparent;
    border-radius: 24px;
    transition: all 0.3s ease;
    cursor: pointer;
    opacity: 100%;
    
    z-index: 10;
    margin: 0;
    padding: 0;
}

.icon-arrow-up{
    color: var(--pink);
    transition: all 0.3s ease;
    font-size: 24px;
    height: 24px;
    display: inline-block;
}

@media only screen and (max-width: 1100px){
    .scrolltotopwrapper{
        display: flex;
        justify-content: flex-end;
        padding: 32px 24px 32px 0;
        background-color: var(--purple);
    }

    .opacity0{
        opacity: 1;
        transition: all 0.3s ease;
        background-color: transparent;
        border: 4px solid var(--pink);
        border-radius: 24px;
        width: 56px;
        height: 56px;
        z-index: 10;
    }
}

@media only screen and (min-width: 1100px){

    .sttbutton{
        position: absolute;
        bottom: 144px;
        right: 120px;
    }

    .opacity0{
        opacity: 0;
        transition: all 0.3s ease;
        background-color: transparent;
        border: 4px solid var(--pink);
        border-radius: 24px;
        width: 56px;
        height: 56px;
        position: absolute;
        bottom: 144px;
        right: 120px;
        margin-top: 10rem;
        z-index: 10;
    }

    .sttbutton:hover {
        background-color: var(--pink);
        height: 64px;
        padding-bottom: 8px;
    }

    .scrolltotopwrapper{
        position: sticky;
        bottom: 0;
        z-index: 10;
    }

    .sttbutton:hover .icon-arrow-up{
        color: white;
    }
}