@import '/src/colors.css';
@import '/src/fonts/fonts.css';
@import '/src/button.css';

.heroimage{
  display: flex;
  justify-content: center;
    background: 
    linear-gradient(
      rgba(27,16,57,0.4), 
      rgba(27,16,57,0.4)
    ),
    linear-gradient(
      rgba(100,100,100,0.2), 
      rgba(100,100,100,0.2)
    ),
    url('../../../../sources/Bilder/Heroimage.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /*background: rgba(27,16,57,0.4);*/
}

.herowrapper{
  display: flex;
  flex-direction: column;
  max-width: 996px;
}

.heroheading{
  overflow: visible;
  color: white;
}

@media only screen and (max-width: 560px){ 
  .heroimage{
    padding:128px 16px 64px 16px;
  }

  .herowrapper{ 
    align-items: center;
  }

  .logo{
    height: 64px;
    padding-top: 48px;
  }

  .heroheading{
    text-align: center;
  }
}

@media only screen and (min-width: 560px){
  .heroimage{
    padding:162px 24px 64px 24px;
  }

  .herowrapper{ 
    align-items: center;
  }

  .logo{
    width: 238px;
    height: 82px;
    padding-top: 48px;
  }

  .heroheading{
    text-align: center;
  }
}

@media only screen and (min-width: 1100px){
  .heroimage{

    padding:182px 222px 64px 222px;
  }

  .herowrapper{ 
    align-items: flex-end;
  }

  .heroheading{
    text-align: right;
  }
}