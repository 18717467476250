@import '/src/colors.css';
@import '/src/fonts/fonts.css';
@import '/src/button.css';


.faqwrapper{
    background-color: var(--purple);
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.faqtext{
    color: white;
    overflow: hidden;
}

.faqcontent{
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.faqtextwrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.faqtextheading{
    font-weight: bold;
    color: white;
    font-size: 120%;
}

@media only screen and (max-width: 346px){
}

@media only screen and (max-width: 560px){
    .faqcontent{
        padding: 128px 16px 0px 16px;
        width: calc(100% - 32px);
        padding-bottom: 80px;
    }

    .faqwrapper{
        align-items: center;
    }

    .faqheading{
        text-align: center;
    }

    .faqheader{
        justify-content: center;
    }
}

@media only screen and (min-width: 560px){
    .faqcontent{
        padding: 162px 24px 0px 24px;
        padding-bottom: 144px;
    }

    .faqwrapper{
        align-items: center;
    }

    .faqheading{
        text-align: center;
    }

    .faqheader{
        justify-content: center;
    }
}

@media only screen and (min-width: 1100px){
    .faqheader{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    
    .faqcontent{
        padding: 182px 324px 144px 324px;
    }

    .faqwrapper{
        align-items: flex-start;
    }

    .faqheading{
        text-align: left;
    }
}