 @import '/src/colors.css';
@import '/src/fonts/fonts.css';
@import '/src/button.css';

.pictures-wrapper{
    background-color: var(--purple);
    height: fit-content;
    gap: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pictures-textwrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
}

.pictures-contenttext{
    color: white;
    margin: 0;
}

.pictures-contentheading{
    color: white;
    overflow: visible;
    margin: 0;
    width: 100%;
    max-width: 1000px;
    box-sizing: border-box;
}

@media only screen and (max-width: 560px){
    .pictures-wrapper{
        padding: 56px 0 56px 0;
    }

    .pictures-contentheading{
        padding: 0px 16px 0px 16px;
        text-align: center;
    }

    .pictures-textwrapper{
        padding: 0px 16px 0px 16px;
    }
}

@media only screen and (min-width: 560px){
    .pictures-wrapper{
        padding: 104px 0 104px 0;
    }

    .pictures-contentheading{
        padding: 0px 24px 0px 24px;
        text-align: center;
    }

    .pictures-textwrapper{
        padding: 0px 24px 0px 24px;
        align-items: center;
    }
}

@media only screen and (min-width: 1100px){
    .pictures-wrapper{
        padding: 104px 220px 104px 220px;
    }

    .pictures-contentheading{
        padding: 0px 102px 0px 102px;
        text-align: left;
        max-width: 996px;
    }

    .pictures-textwrapper{
        padding: 0px 102px 0px 102px;
        max-width: 996px;
        align-items: flex-start;
    }
}