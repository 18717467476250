@import '/src/colors.css';
@import '/src/fonts/fonts.css';


section{
    width: 100%;
}

.nextcontent{
    background-color: var(--purple);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.next-links-wrapper{
    display: inline-block;
}

.nextcontent .next-content-heading-section p{
    margin-top: 32px;
}

.nextcontent a{
    color: var(--pink);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 22px;
}

.nextcontent a .icon-link-extern{
    font-size: 22px;
    height: 22px;
    display: inline-block;
    margin-left: 4px;
    margin-right: 2px;
    transition: all ease 0.3s;
}

.nextcontent a:hover .icon-link-extern{
    margin-left: 8px;
}

.nextcontent .next-content-heading-section .next-about-link i{
    font-size: 16px;
}

.next-content-heading-section > div{
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.next-content-heading-section > div i{
    margin-left: 4px;
    margin-right: 2px;
    transition: all ease 0.3s;
    height: 17px;
}

.next-content-heading-section > div a:hover i{
    margin-left: 8px;
}

.next-content-heading-section > div a{
    height: fit-content;
}

.next-wrapper .nexteventwrapper .mainheading, .next-wrapper .nexteventwrapper .button{
    display: none;
}

.next-await-section ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.next-await-section div{
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.next-await-section ul li{
    margin-top: 8px;
}

.next-await-section li p{
    display: inline-block;
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.next-await-section li .icon-bullet{
    font-size: 22px;
    height: 22px;
    display: inline-block;
    padding-right: 8px;
}

.timetable-wrapper, .timetable-section, .event-location{
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
}

@media only screen and (max-width: 560px){
    .nextcontent{
        gap: 56px;
    }

    .next-content-heading-section{
        padding: 162px 16px 0px 16px;

    }

    .next-await-section, .event-location, .timetable-wrapper{
        padding: 0px 16px 0px 16px;
        width: 100%;
    }

    .siteplan{
        margin-bottom: 24px;
    }

    .nextcontent section h1 {
        width: 100%;
        text-align: center;
    }
    .next-await-section div{
        flex-direction: column;
    }

    .next-await-section h2{
        text-align: center;
    }
    
    .timetable-svg{
        width: 720px;
        height: 686.37px;
    }

    .timetable-svg-wrapper{
        width: 100%;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }

    .timetable-svg-wrapper::-webkit-scrollbar{
        display: none;
    }
}

@media only screen and (max-width:700px){
    .next-await-section div{
        flex-direction: column;
    }  
}

@media only screen and (min-width: 560px){
    .nextcontent{
        gap: 104px;
    }


    .next-content-heading-section{
        padding: 162px 24px 0px 24px;

    }

    .next-await-section, .event-location, .timetable-wrapper{
        padding: 0px 24px 0px 24px;
        width: 100%;
    }

    .siteplan{
        margin-bottom: 24px;
        max-width: 720px;
    }

    .next-content-heading-section > div{
        max-width: none
    }

    .nextcontent section h1 {
        width: 100%;
        text-align: center;
    }

    .next-await-section h2{
        text-align: center;
    }

    .timetable-svg{
        width: 720px;
        height: 686.37px;
    }

    .timetable-svg-wrapper{
        width: 100%;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        display: flex;
        justify-content: center;
    }

    .timetable-svg-wrapper::-webkit-scrollbar{
        display: none;
    }
}

@media only screen and (max-width:770px){
    .timetable-svg-wrapper{
        display: block;
    } 
}

@media only screen and (min-width: 1100px){
    .nextcontent section h1 {
        max-width: 996px;
        width: 100%;
        text-align: left;
    }

    .nextcontent .next-await-section , .event-location, .timetable-section{
        padding: 0px 222px 0px 222px;
        max-width: 1236px;
    }

    .siteplan{
        margin-bottom: 144px;
        max-width: 996px;
    }
    
    .next-content-heading-section{
        padding: 162px 222px 0px 222px;
        max-width: 1440px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .next-content-heading-section > div, .next-content-heading-section p{
        max-width: 792px
    }

    .next-await-section h2{
        text-align: left;
    }

    .timetable-svg{
        width: 996px;
        height: 950px;
    }

    .timetable-svg-wrapper{
        width: fit-content;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }

    section h2{
        width: 100%;
    }

    .siteplan{
        max-width: 996px;
    }
}