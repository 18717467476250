@import '/src/colors.css';
@import '/src/fonts/fonts.css';

.menu-logo{
    background: url(/src/sources/LuL-Logo/lul_logo_wort_bildmarke_weiss.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.navbar-items{
    width: fit-content;
    position: fixed;
    z-index: 1000;
}
.navbar-items.active{
    width: 100%;
}

.nav-links .icon-arrow-right, .nav-links .icon-link-extern, .nav-links .icon-arrow-small-down{
    font-size: 4vh;
    color: white;
    height: 4vh;
    display: inline-block;
    transition: all 0.3s ease;
}

.nav-links .icon-arrow-small-up{
    font-size: 4vh;
    color: var(--purple2);
    height: 4vh;
    display: inline-block;
    transition: all 0.3s ease;
}

.icon-close{
    font-size: 7vh;
    color: white;
    height: 7vh;
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s ease;
}

.icon-burger-menu{
    font-size: 7vh;
    color: white;
    height: 7vh;
    display: inline-block;
}

.icon-service_mail, .icon-service_insta{
    font-size: 7vh;
    color: white;
    height: 7vh;
    display: inline-block;
    transition: all 0.3s ease;
}

#mail-link{
    margin-left: 24px;
}

.nav-links-text{
    color: white;
    display: inline-block;
    line-height: 4vh;
    font-size: 4vh;
    margin: 0 0.5rem 0 0;
    transition: all 0.3s ease;
}

.nav-links{
    display: flex;
    text-align: left;
    justify-content: center;
    padding: 3vh 0px;
    width: fit-content;
    height: 10vh;
    overflow-y: hidden;
    cursor: pointer;
}

.underpoint .nav-links{
    padding: 1vh 0px;
    height: fit-content;
}

.underpoint{
    padding-left: 32px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.6s ease;
}

.nav-menu .nav-links-wrapper .overpoint-active .nav-links-text{
    color: var(--purple2);
}

.nav-menu .nav-links-wrapper .underpoint-active{
    max-height: 12vh;
}

.nav-other-links-wrapper{
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 40px;
}

.nav-links-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    max-width: fit-content;
    width: fit-content;
    height: fit-content;
}

.nav-menu{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    top: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    padding: 0;
    margin: 0;
    list-style: none;
}

.nav-menu.active{
    background: var(--purple);
    top: 0;
    opacity: 1;
    transition: all 0.5s ease;
    list-style: none;
}

a{
    text-decoration: none;
}

.menu-icon{
    align-self: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    cursor: pointer;
}

.logo-icon{
    align-self: center;
    display: flex;
    width: fit-content;
    height: fit-content;
    text-decoration: none;
}

@media only screen and (max-width: 560px){
    .menu-logo{
        width: 185.76px;
        height: 64px;
    }

    .navbar-wrapper{
        padding: 3vh 0 40px 24px;
        width: 100%;
    }

    .nav-links, .nav-other-links{
        margin-left: 24px;
    }

    .menu-close{
        margin: 3vh 0px 2vh 24px;
        height: fit-content;
    }
}

@media only screen and (min-width: 560px){
    .menu-logo{
        width: 238px;
        height: 82px;
    }

    .navbar-wrapper{
        padding: 24px 0 40px 24px;
        width: 100%;
    }

    .nav-links, .nav-other-links{
        margin-left: 24px;
    }

    .menu-close{
        margin: 3vh 0px 2vh 24px;
        height: fit-content;
    }
}

@media only screen and (min-width: 1100px){
    .navbar-wrapper{
        padding: 40px 0 40px 120px;
        width: 100%;
    }

    .nav-links, .nav-other-links{
        margin-left: 120px;
    }

    .menu-close{
        margin: 3vh 0px 2vh 120px;
        height: fit-content;
    }

    .overpoint.nav-links:hover p {
        color: var(--pink);
        margin-right: 0.5rem;
        transition: all 0.3s ease;
    }

    .nav-links:hover p {
        color: var(--pink);
        margin-right: 1rem;
        transition: all 0.3s ease;
    }

    .nav-menu .nav-links-wrapper .overpoint-active:hover .nav-links-text{
        color: var(--pink);
    }

    .nav-other-links:hover .icon-service_insta, .nav-other-links:hover .icon-service_mail {
        color: var(--pink);
    }

    .menu-close .icon-close:hover{
        color: var(--pink);
    }

    .nav-links:hover .icon-arrow-right, .nav-links:hover .icon-arrow-small-down, .nav-links:hover .icon-arrow-small-up, .nav-links:hover .icon-link-extern {
        color: var(--pink);
    }

    .overpoint.nav-links:hover .icon-arrow-small-down {
        margin-top: 0.5rem;
        transition: all 0.3s ease;
    }
    
    .overpoint.nav-links:hover .icon-arrow-small-up {
        margin-top: -0.5rem;
        transition: all 0.3s ease;
    }
}