@import '/src/colors.css';
@import '/src/fonts/fonts.css';

.slider{
    height: 544px;
    width: 100%;
    overflow: hidden;
}

.sliderbuttons{
    display: flex;
    gap: 3px;
    justify-content: center;
    width: 100%;
    padding-top: 16px;
}

.sliderbuttons label{
    bottom: 0;
    width: 40px;
    height: 6px;
    background : var(--pink);
    transform: skew(20deg);
    cursor: pointer;
    transition: ease all 0.2s;
}

.sliderbuttons input{
    opacity: 0;
    width: 0;
    margin: 0;
    height: 0;
}

.sliderbuttons input:checked + label{
    background: #A49FB0;
    cursor: auto;
}

.slides{
    width: 500%;
    display: flex;
    height: 496px;
}

.slide{
    width: 20%;
    transition: 2s;
}

.pic1{
    margin-left: 0;
}

.pic2{
    margin-left: -20%;
}

.pic3{
    margin-left: -40%;
}

.pic4{
    margin-left: -60%;
}

.pic5{
    margin-left: -80%;
}


#slide1{
    background-image: url("/src/sources/Bilder/Slide1.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#slide2{
    background-image: url("/src/sources/Bilder/Slide2.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#slide3{
    background-image: url("/src/sources/Bilder/Slide3.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#slide4{
    background-image: url("/src/sources/Bilder/Slide4.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#slide5{
    background-image: url("/src/sources/Bilder/Slide5.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (min-width: 1100px){
    .slider{
        max-width: 996px;
    }

    .sliderbuttons input:hover + label{
        height: 8px;
    }
    
    .sliderbuttons input:hover:checked + label{
        height: 6px;
    }
}