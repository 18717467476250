@import '/src/colors.css';
@import '/src/fonts/fonts.css';

.button{
    box-sizing: border-box;
    overflow: hidden;
    height: 56px;
    border: 4px solid #F32583;
    border-radius: 24px;
    padding: 12px 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 32px;
}

.button .buttontext{
    display: inline-block;
    margin: 0 8px 0 0;
    transition: all 0.3s ease;
}

.icon-arrow-right, .icon-link-extern{
    font-size: 24px;
    height: 24px;
    display: inline-block;
    transition: all 0.3s ease;
}

.purple{
    color: var(--purple);
}

.white{
    color: white;
}

.pink{
    color: var(--pink);
}

.purplebackground{
    background: rgba(27,16,57,0.4);
    transition: all 0.3s ease;
}

@media only screen and (max-width: 560px){
    .button{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
      }
}

@media only screen and (min-width: 560px){
    .button{
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .button:hover {
        background-color: var(--pink);
    }

    .button:hover .buttontext{
        margin-right: 16px;
    }

    .button:hover .purple, .button:hover .pink, .button:hover .white{
        color: white;
    }
}

@media only screen and (min-width: 1100px){

}