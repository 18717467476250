@import '/src/colors.css';
@import '/src/fonts/fonts.css';
@import '/src/button.css';

.cta-wrapper{
    background-color: var(--purple);
}

.cta-content-wrapper{
    display: flex;
    justify-content: center;
    position: relative;
}

.cta{
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    z-index: 0;
}

.cta-content{
    box-sizing: border-box;
    width: 100%;
    z-index: 1;
}

.cta-fill-rest{
    background-color: var(--blue);
    width: 100%;
}

.svg-wrapper{
    width: fit-content;
}

@media only screen and (max-width: 560px){
    .cta-content{
        position: relative;
        padding: 64px 24px 64px 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .cta-content .button{
        width: fit-content;
    }

    .cta-logo-cut-out-1440{
        display: none;
    }
    
    .cta-logo-cut-out-mobile{
        display: block;
        height: 100%;
        fill: var(--blue);
        min-width: 0;
    }

    .cta-header{
        display: inline-block;
        width: 100%;
        text-align: right;
    }

    .cta-content-wrapper{
        min-height: 248px;
        padding-bottom: 8px;
    }

    .cta{
        box-sizing: border-box;
        padding-bottom: 8px;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 560px){

    .cta-content{
        position: relative;
        padding: 64px 24px 64px 24px;
        width: 100%;
    }

    .cta-content .button{
        margin-left: 160px;
    }

    .cta-logo-cut-out-1440{
        display: none;
    }
    
    .cta-logo-cut-out-mobile{
        display: block;
        height: 100%;
        fill: var(--blue);
        min-width: 0;
    }

    .cta-header{
        display: inline-block;
        width: 100%;
        text-align: right;
    }

    .cta-content-wrapper{
        min-height: 286px;
        padding-bottom: 24px;
    }

    .cta{
        box-sizing: border-box;
        padding-bottom: 24px;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 1100px){

    .cta-content{
        position: relative;
        padding: 64px 104px 64px 104px;
        max-width: 996px;
    }

    .cta-content .button{
        margin-left: 96px;
    }

    .cta-header{
        display: inline-block;
        width: 100%;
        text-align: right;
    }

    .cta-content-wrapper{
        min-height: 286px;
        margin: 0 220px 0 220px;
        padding-bottom: 144px;
    }

    .cta{
        box-sizing: border-box;
        padding-bottom: 144px;
        width: 100%;
        height: 100%;
        max-width: 1000px;
    }

    .cta-logo-cut-out-1440{
        display: block;
        height: 100%;
        fill: var(--blue);
        box-sizing: border-box;
        min-width: 0;
    }

    .cta-logo-cut-out-mobile{
        display: none;
    }

    .cta-fill-rest{
        background-color: var(--blue);
        width: 100%;
        border-radius: 0 40px 40px 0 ;
    }
    
  }