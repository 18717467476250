@font-face {
    font-family: "Argithea";
    src: url("./argithea-serif-font/Argithea-Demo.OTF") format("opentype");
}

@font-face {
    font-family: "Karla";
    src: url("./Karla/static/Karla-VariableFont_wght.ttf") format("truetype");
    font-weight: 500 800;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6, .keyfigure-date, .keyfigure-counter{
    font-family: Argithea;
    font-weight: normal;
    margin: 0;
    overflow: visible;
}

p, .p-highlight, .bu, .footnote, .buttontext, .intro{
    font-family: Karla;
    margin: 0;
    overflow: visible;
}

.white{
   color: white;
}

@media only screen and (max-width: 560px){

    h1{
        font-size: 40px;
        line-height: 54px;
    }
    
    h2{
        font-size: 36px;
        line-height: 48px;
    }
    
    h3{
        font-size: 32px;
        line-height: 42px;
    }

    h4{
        font-size: 28px;
        line-height: 36px;
    }

    h5{
        font-size: 24px;
        line-height: 30px;
    }

    h6{
        font-size: 20px;
        line-height: 24px;
    }

    .buttontext{
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
    }

    .keyfigure-date{
        font-size: 240px;
        line-height: 240px;
    }

    .keyfigure-counter{
      font-size: 96px;
      line-height: 96px;
    }

    p{
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
    }

    .p-highlight{
        font-size: 18px;
        line-height: 24px;
        font-weight: 800;
    }

    .bu{
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
    }

    .footnote{
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
    }

    .intro{
      font-size: 24px;
      line-height: 32px;
    }
}

@media only screen and (min-width: 560px){
    h1{
        font-size: 56px;
        line-height: 78px;
    }
    
    h2{
        font-size: 48px;
        line-height: 66px;
    }
    
    h3{
        font-size: 40px;
        line-height: 54px;
    }

    h4{
        font-size: 32px;
        line-height: 42px;
    }

    h5{
      font-size: 24px;
      line-height: 34px;
    }

    p{
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
    }

    .buttontext{
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
    }

    .p-highlight{
        font-size: 18px;
        line-height: 24px;
        font-weight: 800;
    }

    .bu{
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
    }

    .footnote{
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }

    .keyfigure-date{
      font-size: 240px;
      line-height: 240px;
    }

    .keyfigure-counter{
      font-size: 136px;
      line-height: 136px;
    }

    .intro{
      font-size: 24px;
      line-height: 32px;
    }
}

@media only screen and (min-width: 1100px){
    h1{
        font-size: 80px;
        line-height: 96px;
    }
    
    h2{
        font-size: 56px;
        line-height: 70px;
    }
    
    h3{
        font-size: 48px;
        line-height: 60px;
    }

    h4{
        font-size: 40px;
        line-height: 50px;
    }

    h5{
        font-size: 32px;
        line-height: 40px;
    }

    h6{
        font-size: 24px;
        line-height: 30px;
    }

    .keyfigure-date{
        font-size: 240px;
        line-height: 240px;
    }

    .keyfigure-counter{
      font-size: 136px;
      line-height: 136px;
    }
}

@font-face {
  font-family: 'icomoon';
  src:  url('./iconfont/icomoon.eot');
  src:  url('./iconfont/icomoon.eot') format('embedded-opentype'),
    url('./iconfont/icomoon.ttf') format('truetype'),
    url('./iconfont/icomoon.woff') format('woff'),
    url('./iconfont/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-return:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-arrow-small-down:before {
  content: "\e905";
}
.icon-arrow-small-left:before {
  content: "\e906";
}
.icon-arrow-small-right:before {
  content: "\e907";
}
.icon-arrow-small-up:before {
  content: "\e908";
}
.icon-bullet:before {
  content: "\e909";
}
.icon-burger-menu:before {
  content: "\e90a";
}
.icon-check-empty:before {
  content: "\e90b";
}
.icon-check-mark:before {
  content: "\e90c";
}
.icon-close:before {
  content: "\e90d";
}
.icon-link-extern:before {
  content: "\e90e";
}
.icon-play:before {
  content: "\e90f";
}
.icon-service_insta:before {
  content: "\e910";
}
.icon-service_mail:before {
  content: "\e911";
}
.icon-slash:before {
  content: "\e912";
}
  
  