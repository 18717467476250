@import '/src/colors.css';
@import '/src/fonts/fonts.css';

.koncept-wrapper{
    width: 100%;
    background-color: var(--purple);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 104px;
}

.koncept-heading-section, .koncept-gif-section{
    background-color: var(--purple);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.koncept-wrapper .price-section{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 32px;
    align-items: center;
}

.koncept-wrapper .price-section .footnote{
    width: 100%;
}

.price-svg{
    overflow: visible;
}

.koncept-gif-text{
    display: flex;
    flex-direction: column;
    gap: 16px;
    opacity: 0;
}

.koncept-gif-section div h2{
    padding-bottom: 8px;
}

#konceptlogogif{
    width: 100%;
}

.koncept-gif-text > *{
    opacity: inherit;
    transition: all 0.3s ease;
}

.koncept-gif-text.show{
   opacity: 100;
}

.koncept-gif-section{
    gap: 40px;
}

.koncept-text-wrapper{
    width: 100%;
    gap: 32px;
}


@media only screen and (max-width: 560px){
    .price-section{
        padding: 0px 16px 24px 16px;
    }

    .koncept-heading-section{
        padding: 162px 16px 0px 16px;

    }

    .koncept-gif-section{
        padding: 0px 16px 0px 16px;
    }

    .koncept-text-wrapper img{
        width: 100%;
        margin: 64px 0px;
    }
}

@media only screen and (min-width: 560px){
    .price-section{
        padding: 0px 24px 24px 24px;
    }

    .koncept-heading-section{
        padding: 162px 24px 0px 24px;

    }

    .koncept-gif-section{
        padding: 0px 24px 0px 24px;
    }

    .koncept-gif-section .koncept-text-wrapper img:nth-of-type(even){
        float: left;
        height: 24vh;
        margin: 24px 24px 24px 0px;
    }

    .koncept-gif-section .koncept-text-wrapper img:nth-of-type(odd){
        float: right;
        height: 24vh;
        margin: 24px 0px 24px 24px;
    }
}

@media only screen and (min-width: 1100px){

    .koncept-gif-section .koncept-text-wrapper img:nth-of-type(even){
        float: left;
        height: 30vh;
        margin: 24px 24px 24px 0px;
    }

    .koncept-gif-section .koncept-text-wrapper img:nth-of-type(odd){
        float: right;
        height: 30vh;
        margin: 24px 0px 24px 24px;
    }


    .price-section{
        padding: 0px 222px 144px 222px;
        max-width: 1236px;
    }

    .koncept-heading-section{
        padding: 162px 222px 0px 222px;
        max-width: 1440px;
    }

    .koncept-heading-section p{
        max-width: 792px;
    }

    .koncept-heading-section h1{
        width: 100%;
    }

    .koncept-gif-section{
        padding: 0px 222px 0px 222px;
        max-width: 1236px;
    }
}