@import '/src/colors.css';
@import '/src/fonts/fonts.css';

.awareness-wrapper{
    background-color: var(--purple);
    color: white;
}

.awareness-wrapper section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.awareness-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.awareness-text > div{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.awareness-content section h2{
    display: block;
    width: fit-content;
    text-align: center;
}

.awareness-table ul{
    list-style-type: none;
    margin: 0;
    width: 100%;
}

.awareness-table ul li{
    margin-top: 8px;
}

.awareness-table li p{
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.awareness-table li .icon-bullet{
    font-size: 22px;
    height: 22px;
    display: inline-block;
    padding-right: 8px;
}

.awareness-text p > div{
    display: inline-block;
}

.awareness-text p > div i{
    margin-left: 4px;
    margin-right: 2px;
    transition: all ease 0.3s;
    height: 17px;
}

.awareness-text p > div a:hover i{
    margin-left: 8px;
}

.awareness-text p > div a{
    height: fit-content;
    color: var(--pink);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media only screen and (max-width: 560px){
    .awareness-text{
        padding: 162px 16px 0px 16px;

    }

    .awareness-table{
        padding: 0px 16px 24px 16px;
    }

    .awareness-table ul{
        padding: 0;
    }

    .awareness-content{
        gap: 56px;
    }
}

@media only screen and (min-width: 560px){

    .awareness-text{
        padding: 162px 24px 0px 24px;

    }

    .awareness-table{
        padding: 0px 24px 24px 24px;

    }

    .awareness-table ul{
        padding: 0 124px 0 0;
    }

    .awareness-content{
        gap: 104px;
    }
}

@media only screen and (min-width: 1100px){
    .awareness-text{
        padding: 162px 222px 0px 222px;
    }

    .awareness-table{
        padding: 0px 222px 144px 222px;
    }

    .awareness-table ul{
        padding: 0 180px 0 0;
    }

    .awareness-text > div, .awareness-text > p{
        max-width: 792px;
    }

    .awareness-content section h1{
        max-width: 996px;
        width: 100%;
        text-align: left;
    }

    .awareness-content section h2{
        width: 100%;
        text-align: left;
    }

    .awareness-wrapper section{
        max-width: 1236px;
    }
    .awareness-wrapper .awareness-text{
        max-width: 1440px;
    }
}