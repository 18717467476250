@import '/src/colors.css';
@import '/src/fonts/fonts.css';
@import '/src/button.css';


.impressumwrapper{
    background-color: var(--purple);
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.impressumtext{
    color: white;
    overflow: hidden;
}

.impressumcontent{
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.impressumtextwrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media only screen and (max-width: 346px){
}

@media only screen and (max-width: 560px){
    .impressumcontent{
        padding: 128px 16px 0px 16px;
        width: calc(100% - 32px);
        padding-bottom: 80px;
    }

    .impressumwrapper{
        align-items: center;
    }

    .impressumheading{
        text-align: center;
    }

    .impressumheader{
        justify-content: center;
    }
}

@media only screen and (min-width: 560px){
    .impressumcontent{
        padding: 162px 24px 0px 24px;
        padding-bottom: 144px;
    }

    .impressumwrapper{
        align-items: center;
    }

    .impressumheading{
        text-align: center;
    }

    .impressumheader{
        justify-content: center;
    }
}

@media only screen and (min-width: 1100px){
    .impressumheader{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    
    .impressumcontent{
        padding: 182px 324px 144px 324px;
    }

    .impressumwrapper{
        align-items: flex-start;
    }

    .impressumheading{
        text-align: left;
    }
}